import { useEffect, useRef } from 'react';

export const useDidMountHook = (callback) => {
	const didMount = useRef(null);

	useEffect(() => {
		if (callback && !didMount.current) {
			didMount.current = true;
			callback();
		}
	});
};