import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import Arkanoid from './routes/arkanoid.jsx';
import RollDice from './routes/rollDice.jsx';
import Animation from './routes/Animation.jsx';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Routes>
			<Route path={"/"} element={<App />}>
				<Route path={'/'} element={<h1>Main page</h1>}/>
				<Route path={"arkanoid"} element={<Arkanoid />}/>
				<Route path={"manyball"} element={<h1>manyball</h1>}/>
				<Route path={"dungeon"} element={<h1>D&D3</h1>}/>
				<Route path={"rollDice"} element={<RollDice />}/>
				<Route path={"animation"} element={<Animation />}/>
				<Route
					path={"*"}
					element={
						<main style={{padding: '1rem'}}>
							<p>There`s nothing here</p>
						</main>
					}
				/>
			</Route>
		</Routes>
	</BrowserRouter>
);
