import React from 'react';
import game from '../game';

export default () => {
	return (
		<div className={'container'}>
			<h2>arkanoid</h2>
			<canvas id="myCanvas" width={480} height={320}>not work js</canvas>
			<button onClick={() => game()}>Start</button>
		</div>
	);
}