import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { randomizer, useDidMountHook } from '../utils';

const TableWrap = styled.div`
  margin: 5px auto;
  width: 30%;
  min-width: max-content;
  overflow: hidden;
`;

const Elem = styled.div`
  display: block;
  text-align: left;
  margin: 3px 0;
`;

const NumElem = styled.span`
  display: inline-block;
  width: 25px;
  //text-align: left;
`;

const GraphElem = styled.div`
  display: inline-block;
  color: coral;
  height: 20px;
  width: 150px;
  padding-left: 5px;
  background: ${({gradik = 0}) => (`linear-gradient(90deg, #141414 ${gradik}%, #fff ${gradik}%)`)};
  border: 1px grey solid;
`;

export default () => {

	const [result, setResult] = useState(Array(21).fill(0))
	const [iteration, setIteration] = useState(0);
	const [iterationMax, setIterationMax] = useState(100);
	const [countSpeed, setCountSpeed] = useState(0);


	const startRoll = (ii = 1) => {
		if(ii === 1) {
			console.log('start');
			// setIteration((iteration) => 0);
			const defaultArr = Array(21).fill(0)
			setResult(() => [...defaultArr])
			console.log('result', result);
		}
		if(ii >= iterationMax) return
		const val = randomizer(20, 1)
		result[val] = result[val]+1;
		setResult(() => [...result]);
		ii++;
		setIteration(iteration + ii)
		setTimeout(() => {
			startRoll(ii)
		}, 1000/600)
	};

	const handleChangeMaxVal = (ev) => {
		setIterationMax(ev.target.value)
	};

	const handleChangeSpeed = (ev) => {
		return void 0;
		setCountSpeed(ev.target.value);
	}

	return (
		<div className="container">
			<h1>RollDice d20</h1>
			<input type="text" value={iterationMax} onChange={handleChangeMaxVal}/>
			<button onClick={() => startRoll()}>Start roll</button>
			{/*<h1>Speed count: {iterationSpeed}</h1>*/}
			{/*<input type="range" onChange={handleChangeSpeed} />*/}
			<p>Iteration: {iteration}</p>
			<p>Result rolling:</p>
			<TableWrap>
				{result.map( (item, i ) => {
						if(i === 0) return false
						return <Elem><NumElem>{i}</NumElem>: <GraphElem >{item}</GraphElem></Elem>;
					}
				)}
			</TableWrap>
		</div>
	);
};