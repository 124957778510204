import React from 'react';
import styled from 'styled-components';

const Button = styled.div`
	display: inline-block;
  box-sizing: border-box;
  width: 100px;
  height: 50px;
  margin-right: 10px;
  padding: 5px;
  background-color: aquamarine;
  color: coral;
  border-radius: 10px;
  border:  3px solid transparent;
  &:hover {
    background-color: cadetblue;
    border:  3px solid coral;
  }
`;

export default () => {
	return (
		<div className={'container'}>
			<Button>Первая</Button>
			<Button>Вторая</Button>
			<Button>Третья</Button>
			<Button>Четвертая</Button>
		</div>
	);
}
