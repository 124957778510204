let gameInProcess = false;
let interval = undefined;

export default () => {

	const canvas = document.getElementById('myCanvas');
	const ctx = canvas.getContext('2d');

	const example = function () {
		// прямоугольник
		ctx.beginPath();
		ctx.rect(20, 40, 50, 50);
		ctx.fillStyle = '#FF0000';
		ctx.fill();
		ctx.closePath();

		// шар
		ctx.beginPath();
		ctx.arc(240, 160, 20, Math.PI * 2, false);
		ctx.fillStyle = 'green';
		ctx.fill();
		ctx.closePath();


		//
		ctx.beginPath();
		ctx.rect(160, 10, 100, 40);
		ctx.strokeStyle = 'rgba(0, 0, 255, 0.5)';
		ctx.stroke();
		ctx.closePath();
	};


	const getRandomInt = function (min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
	};

	const generateColor = function () {
		return '#' + (Math.random().toString(16) + '000000').substring(2, 8).toUpperCase();
	};


	// let gameInProcess = false;
	// let interval = undefined

	// ball
	let x = getRandomInt(0, canvas.width);
	let y = getRandomInt(0, canvas.height);
	let dx = 2;
	let dy = -2;
	const ballRadius = 10;
	let colorBall = '#0095DD';
	console.log(x, y);

	// paddle
	const paddleHeight = 10;
	const paddleWidth = 75;
	let paddleX = (canvas.width - paddleWidth) / 2;
	const colorPaddle = '#0095DD';
	let rightPressed = false;
	let leftPressed = false;

	// brick
	const brickRowCount = 3;
	const brickColumnCount = 5;
	const brickWidth = 75;
	const brickHeight = 20;
	const brickPadding = 10;
	const brickOffsetTop = 30;
	const brickOffsetLeft = 30;

	 // score

	let score = 0;

	const bricks = [];
	for (let c = 0; c < brickColumnCount; c++) {
		bricks[c] = [];
		for (let r = 0; r < brickRowCount; r++) {
			bricks[c][r] = { x: 0, y: 0, status: 1 };
		}
	}
	console.log('bricks', bricks);
	const drawBall = function (x, y) {
		ctx.beginPath();
		ctx.arc(x, y, ballRadius, 0, Math.PI * 2);
		ctx.fillStyle = colorBall;
		ctx.fill();
		// ctx.strokeStyle = "rgba(255,0,183,0.5)";
		// ctx.stroke();
		ctx.closePath();
	};

	const drawPaddle = function () {
		ctx.beginPath();
		ctx.rect(paddleX, canvas.height - paddleHeight, paddleWidth, paddleHeight);
		ctx.fillStyle = colorPaddle;
		ctx.fill();
		ctx.closePath();
	};

	const drawBricks = function () {
		for (let c = 0; c < brickColumnCount; c++) {
			for (let r = 0; r < brickRowCount; r++) {
				if (bricks[c][r].status == 1) {
					const brickX = (c * (brickWidth + brickPadding)) + brickOffsetLeft;
					const brickY = (r * (brickHeight + brickPadding)) + brickOffsetTop;
					bricks[c][r].x = brickX;
					bricks[c][r].y = brickY;
					ctx.beginPath();
					ctx.rect(brickX, brickY, brickWidth, brickHeight);
					ctx.fillStyle = '#0095DD';
					ctx.fill();
					ctx.closePath();
				}
			}
		}
	};

	const keyDownHandler = function (e) {
		if (e.keyCode == 39) {
			rightPressed = true;
		} else if (e.keyCode == 37) {
			leftPressed = true;
		}
	};

	const keyUpHandler = function (e) {
		if (e.keyCode == 39) {
			rightPressed = false;
		} else if (e.keyCode == 37) {
			leftPressed = false;
		}
	};

	const mouseMoveHandler = function(e) {
		let relativeX = e.clientX - canvas.offsetLeft;
		if(relativeX > 0 && relativeX < canvas.width) {
			paddleX = relativeX - paddleWidth / 2;
		}
	};

	const collisionDetection = function () {
		for (let c = 0; c < brickColumnCount; c++) {
			for (let r = 0; r < brickRowCount; r++) {
				const b = bricks[c][r];
				if (b.status == 1) {
					if (x > b.x && x < b.x + brickWidth && y > b.y && y < b.y + brickHeight) {
						dy = -dy;
						b.status = 0;
						score++;
						if(score === brickRowCount * brickColumnCount) {
							alert("WIN");
							document.location.reload();
						}
					}
				}
			}
		}
	};

	const drawScore = function () {
		ctx.font = "16px Arial";
		ctx.fillStyle = "#0095DD";
		ctx.fillText("Score: " + score, 8, 20);
	};

	const draw = function () {
		// console.log(1);
		gameInProcess = true;
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		drawBricks();
		drawBall(x, y);
		drawPaddle();
		collisionDetection();
		drawScore();
		//ball
		if (x + dx > canvas.width - ballRadius || x + dx < ballRadius) {
			dx = -dx;
			colorBall = generateColor();
		}

		// if(y + dy > canvas.height - ballRadius || y + dy < ballRadius) {
		// 	dy = -dy;
		// 	colorBall = generateColor();
		// }
		if (y + dy < ballRadius) {
			dy = -dy;
			colorBall = generateColor();
		} else if (y + dy + paddleHeight > canvas.height - ballRadius) {
			if (x > paddleX && x < paddleX + paddleWidth) {
				dy = -dy;
				colorBall = generateColor();
			} else {
				console.log('GAME OVER');
				gameInProcess = false;
				// clearInterval(interval); // Needed for Chrome to end game
				alert('GAME OVER');
				// document.location.reload();
			}
		}
		// paddle
		if (rightPressed && paddleX < canvas.width - paddleWidth) {
			paddleX += 7;
		} else if (leftPressed && paddleX > 0) {
			paddleX -= 7;
		}
		x += dx;
		y += dy;
		requestAnimationFrame(draw);
	};

	document.addEventListener('keydown', keyDownHandler, false);
	document.addEventListener('keyup', keyUpHandler, false);
	document.addEventListener('mousemove', mouseMoveHandler, false);
	if (!gameInProcess) {
		// interval = setInterval(draw, 10);
		draw();
	}
}

