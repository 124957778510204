import React from 'react';
import {
	Link,
	Outlet,
} from 'react-router-dom';
import './App.css';
import { useDidMountHook } from './utils/useDidMountHook';

// https://developer.mozilla.org/ru/docs/Games/Tutorials/2D_Breakout_game_pure_JavaScript/Game_over


function App () {

	useDidMountHook(() => {
		// game();
	}, []);

	return (
		<div>
			<h1>War ball</h1>
			<nav style={{
				borderBottom: 'solid 1px',
				paddingBottom: '1rem',

			}}>
				<Link to="/">Main Page</Link> |{' '}
				<Link to="/arkanoid">Arkanoid</Link> |{' '}
				<Link to="/manyball">Ball</Link> |{' '}
				<Link to="/rollDice">RollDice</Link> | {' '}
				<Link to="/dungeon">D&D</Link> | {' '}
				<Link to="/animation">Animation</Link>
			</nav>
			<Outlet />
		</div>
	);

}

export default App;

